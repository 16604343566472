<template>
    <div id="rightTopLeft">
      <div class="bg-color-black">
        <div class="lb-title">{{ title }}</div>
        <div class="lb-content">
          <div class="select-container">
            <el-select
              v-model="workshop_value"
              placeholder="车间编号"
              @change="handleWorkshopSelect"
              style="padding: 10px;"
            >
              <el-option
                v-for="item in workshop_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="production_line_value"
              placeholder="产线编号"
              @change="handleTaskSelect"
            >
              <el-option
                v-for="item in production_line_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="grid-content">
            <div v-for="item in items" :key="item.id" class="grid-item" :style="{ color: getColor(item.id) }">
              <div class="item-top">
                <div class="item-top-num">{{ item.num }}</div>
              </div>
              <div class="item-bottom">{{ item.status }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: "leftTop",
    props: {
      totalProductivity: {
        type: Number,
        required: true,
      },
      totalError: {
        type: Number,
        required: true,
      },
      workshop_value: {
        type: String,
        required: true,
      },
      workshop_options: {
        type: Array,
        required: true,
      },
      production_line_value: {
        type: String,
        required: true,
      },
      production_line_options: {
        type: Array,
        required: true,
      },
      machineNumber: {
        type: Number,
        required: true,
      },
      workingMachineNumber: {
        type: Number,
        required: true,
      }
    },
    data() {
      return {
        title: "产线情况",
        items: [
          { id: 0, num: 0, status: '总产率（小时）' },
          { id: 2, num: 0, status: '总告警个数' },
          { id: 3, num: 0, status: '运行设备数' },
          { id: 1, num: 0, status: '总设备数' },

        ],
      };
    },
    watch: {
      totalProductivity: {
        handler(newVal) {
          this.items[0].num = newVal;
        },
        deep: true,
      },
      totalError: {
        handler(newVal) {
          this.items[1].num = newVal;
        },
        deep: true,
      },
      machineNumber: {
        handler(newVal) {
          this.items[3].num = newVal;
        },
        deep: true,
      },
      workingMachineNumber: {
        handler(newVal) {
          this.items[2].num = newVal;
        },
        deep: true,
      }
    },
    methods: {
      navigateToStatus() {
        this.$router.push({ path: '/status' });
      },
      getColor(id) {
        if (id === 3) {
          return '#999999';
        } else if (id === 2) {
          return '#e14a3b';
        } else if (id === 1) {
          return '#F29D38';
        } else if (id === 0) {
          return '#52962A';
        }
      },
      handleWorkshopSelect() {
        this.$emit('workshopSelect', this.workshop_value);
      },
      handleTaskSelect() {
        this.$emit('taskSelect', this.production_line_value);
      }
    }
  });
  </script>
  
  <style scoped lang="scss">
  #rightTopLeft {
    padding: 10px 16px;
    height: 450px;
    width: 100%;
    border-radius: 5px;
  
    .bg-color-black {
      height: 415px;
      border-radius: 10px;
    }
  
    .chart-title {
      padding: 10px 0 0 20px;
      font-size: 16px;
      font-weight: bold;
    }
    .lb-title {
        padding: 10px 0 0 20px;
        font-size: 24px;
        font-weight: bold;
        color: #25a18e;
        margin-top: 10px;
      }
    .lb-content {
      display: flex;
      flex-direction: column;
      height: 100%;
  
      
      
      .select-container {
        display: flex;
        align-items: center;
        margin: 10px 10px 50px;
        padding: 45px 10px 0 0px;
        height: 0px;
        font-size: 20px;
        font-weight: bold;
  
        .select-item {
          display: flex;
          margin: 5px 10px;
          align-items: center;
          justify-content: center;
          width: 100px;
          height: 100%;
          border-right: 1px solid #ddd;
        }
      }
  
        
      
  
      .grid-content {
        flex: 2;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
  
        .grid-item {
          display: flex;
          flex-direction: column;
  
          .item-top {
            flex: 1;
            display: flex;
            font-weight: bold;
            font-size: 42px;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
  
          .item-bottom {
            flex: 1;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            font-size: 22px;
            font-weight: bold;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  </style>
  