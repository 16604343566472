<template>
    <div>
      <Chart :cdata="cdata"/>
    </div>
  </template>
  
  <script>
  import Chart from './chart.vue'
  
  export default {
    data() {
      return {
        cdata: {
          category: [],
          data: [],
        }
      };
    },
    props: {
      machineProductivity: {
        type: Array,
        required: true,
      },
    },
    watch: {
      machineProductivity: {
        handler: function (newVal) {
          console.log(newVal)
          this.cdata.category = []
          this.cdata.data = []
          newVal.forEach((item, index) => {
            this.cdata.category[index] = item.machine_name
            this.cdata.data[index] = item.day_anomaly_count
          })
        },
        deep: true
      }
    },
    components: {
      Chart,
    },
    mounted() {
    },
    methods: {}
  };
  </script>
  
  <style lang="scss" scoped>
  </style>
  