<template>
  <div id="left">
    <div class="bg-color-black">
        <div class="lb-desc">
      <div class="left-title">{{ getproductionLinename() + this.title }}</div>
      <div class="lb-detail" @click="navigateToStatus">详情</div>
    </div>
      <div>
        <LeftChart :machineProductivity="this.machineProductivity"/>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import LeftChart from '_c/echart/productline/leftBottom'

export default defineComponent({
  name: "left",
  data() {
    return {
      title: "设备产率",
    }
  },
  props: {
    machineProductivity: {
      type: Array,
      required: true,
    },
    production_line_value: {
      type: Number,
      required: true,
    }, 
    production_line_options: {
      type: Object,
      required: true,
    }, 
  },
  components: {
    LeftChart
  },
    methods: {
        navigateToStatus() {
            this.$router.push({ path: '/status' })
        },
        getproductionLinename() {
            let name = '全部'
            this.production_line_options.forEach(item => {
                if (item.value === this.production_line_value) {
                    name = item.label
                }
            })
            name = name + '产线'
            return name
        }  
    },

    })
</script>

<style scoped lang="scss">
$box-height: 450px;
$box-width: 100%;
#left {
  padding: 20px 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .bg-color-black {
    height: $box-height - 35px;
    border-radius: 10px;
  }

  .left-title {
    padding: 10px 0 0 20px;
    font-size: 24px;
    font-weight: bold;
    color: #25a18e;
  }
  .lb-desc {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;

    .lb-title {
      padding: 5px 0 0 20px;
      font-size: 24px;
      font-weight: bold;
      color: #25a18e;
    }

    .lb-detail {
      cursor: pointer;
      padding: 5px 5px 0 20px;
      //padding: 5px 20px 0 0;
      font-size: 22px;
      color: #25a18e;
    
  }
  }
}
</style>
