<template>
    <div>
        <Echart :options="options" id="rightTop" height="380px" width="90%"></Echart>
    </div>
</template>

<script>
import Echart from '@/common/echart/index.vue'

export default {
    data() {
        return {
            options: {},
        };
    },
    components: {
        Echart,
    },
    props: {
        cdata: {
            type: Object,
            default: () => ({})
        },
    },
    watch: {
        cdata: {
            handler(newData) {
                this.options = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    grid: {
                        left: '6%',
                        right: '6%',
                        bottom: '4%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            max: 'dataMax',
                            axisLabel: {
                                textStyle: {
                                    fontSize: 16 
                                },
                                interval: 0,
                            },
                            name: '次数',
                            nameTextStyle: {
                                fontSize: 16
                            },
                        }
                    ],
                    yAxis: [
                        {
                            type: 'category',
                            data: newData.category,
                            nameTextStyle: {
                                fontSize: 16
                            },
                            axisLabel: {
                                textStyle: {
                                    fontSize: 16 
                                },
                                interval: 0,
                            },
                            inverse: true,
                            animationDuration: 300,
                            animationDurationUpdate: 300,
                            //max: 5 // 最多显示5条
                        }
                    ],
                    series: [
                        {
                            realtimeSort: true,
                            name: 'X',
                            type: 'bar',
                            barWidth: '60%',
                            data: newData.data,
                            // 前三个最大的变成红色
                            itemStyle: {
                                normal: {
                                    color: function(params) {
                                        if (params.dataIndex < 3) {
                                            return '#ff4d4f';
                                        } else {
                                            return  '#666';
                                        }
                                    }
                                }
                            },
                            label: {
                                show: true,
                                position: 'right',
                                color: 'white', // 标签颜色
                                fontSize: 16, // 字体大小
                                valueAnimation: true
                            }
                        }
                    ]
                }
            },
            
            immediate: true,
            deep: true
        },
    },
}
</script>