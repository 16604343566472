<template>
    <div id="rightTop">
      <div class="bg-color-black">
        <div class="chart-title">{{ getproductionLinename() + this.title }}</div>
        <div>
          <RightTopChart :machineProductivity="this.machineProductivity" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {defineComponent} from 'vue'
  import RightTopChart from '_c/echart/productline/rightTop'
  
  export default defineComponent({
    name: "rightTop",
    data() {
      return {
        title: "24小时内故障数量",
      }
    },
    props: {
        machineProductivity: {
        type: Array,
        required: true,
    },
      production_line_value: {
      type: Number,
      required: true,
    }, 
    production_line_options: {
      type: Object,
      required: true,
    }, 
    },
    components: {
      RightTopChart
    },
    methods: {
        getproductionLinename() {
            let name = '全部'
            this.production_line_options.forEach(item => {
                if (item.value === this.production_line_value) {
                    name = item.label
                }
            })
            name = name + '产线'
            return name
        }  
    },
  })
  </script>
  
  <style scoped lang="scss">
  $box-height: 450px;
  $box-width: 100%;
  #rightTop {
    padding: 20px 16px;
    height: $box-height;
    width: $box-width;
    border-radius: 5px;
  
    .bg-color-black {
      height: $box-height - 35px;
      border-radius: 10px;
    }
  
    .chart-title {
      padding: 10px 0 0 20px;
      font-size: 24px;
      font-weight: bold;
      color: #25a18e;
    }
  }
  </style>
  