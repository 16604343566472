import http from '../utils/request.js'


const oee = {

    getHourProduct(id) {
        if (!id) id = 1
        const params = {
            workshop_id: id,
        }
        return http.post(`/screen/newProductionBoard`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
    },

}

export default oee;
