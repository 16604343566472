<template>
    <div id="productline" ref="appRef">
        <div class="bg">
            <div class="pl-body">
                <MyHeader :title="title"></MyHeader>
                
                <div class="body-box">
                    <!-- 左侧数据 -->
                    <div class="left-box">
                        <div class="left-top-box">
                            <div>
                                <dv-border-box-12>
                                    <leftTop 
                                        :totalProductivity="totalProductivity"
                                        :totalError="this.totalError" 
                                        :workshop_value="workshop_value"
                                        :workshop_options="workshop_options"
                                        :production_line_value="production_line_value"
                                        :production_line_options="production_line_options"
                                        :machineNumber="this.machineNumber"
                                        :workingMachineNumber="this.workingMachineNumber"
                                        @workshopSelect="handleWorkshopSelect"
                                        @taskSelect="handleTaskSelect"
                                        :oee_trend="this.oee_trend" 
                                    />
                                </dv-border-box-12>
                            </div>
                        </div>
                        <div class="left-bottom-box">
                            <div>
                                <dv-border-box-12>
                                    <leftBottom 
                                        :machineProductivity="this.machineProductivity"
                                        :production_line_value="this.production_line_value"
                                        :production_line_options="this.production_line_options"
                                    />
                                </dv-border-box-12>
                            </div>
                        </div>
                    </div>

                    <!-- 右侧数据 -->
                    <div class="right-box">
                        <div class="right-top-box">
                            <div>
                                <dv-border-box-12>
                                    <rightTop 
                                        :machineProductivity="this.machineProductivity"
                                        :production_line_value="this.production_line_value"
                                        :production_line_options="this.production_line_options"
                                    />
                                </dv-border-box-12>
                            </div>
                        </div>
                        <div class="right-bottom-box">
                            <div>
                                <dv-border-box-12>
                                    <rightBottom :oee_trend="this.oee_trend" />
                                </dv-border-box-12>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import MyHeader from "../../components/header/myHeader.vue";
import drawMixin from "@/utils/drawMixin";
import leftBottom from "@/views/productline/leftBottom.vue";
import leftTop from "@/views/productline/leftTop.vue";
import rightTop from "@/views/productline/rightTop.vue";
import rightTopRight from "@/views/productline/rightTopRight.vue";
import rightBottom from "@/views/productline/rightBottom.vue";
import productline from "@/api/productline";

export default defineComponent({
  name: "productline",
  mixins: [drawMixin],
  data() {
    return {
      title: "产线状态",
      machineProductivity: [],
      totalProductivity: 0,
      totalError: 0,
      machineNumber: 0,
      workingMachineNumber: 0,
      workshop_value: "",
      tempValue: -1,
      production_line_options: [],
      production_line_value: "",
      statusData: [],
      dataStatusBackup: [],
      workshop_options: [
        {
          value: "1",
          label: "ws-001",
        },
        {
          value: "2",
          label: "ws-002",
        },
        {
          value: "3",
          label: "ws-003",
        },
        {
          value: "4",
          label: "ws-004",
        },
        {
          value: "5",
          label: "ws-005",
        },
      ],
    };
  },
  components: {
    rightBottom,
    leftTop,
    leftBottom,
    rightTop,
    rightTopRight,
    MyHeader /**/,
  },
  mounted() {
    // setInterval 轮询策略待实现
    // setInterval(() => {
    //   this.fetchData();
    // }, 3000);
  },
  created() {
    this.fetchData();
  },
  methods: {
    handleWorkshopSelect(val) {
      this.workshop_value = val;
      this.fetchData();
      this.tempValue = -1;
      this.production_line_value = "";
    },
    handleTaskSelect(val) {
      console.log(val, this.production_line_value);
      this.tempValue = val;
      this.production_line_value = val;
      this.fetchData();
    },
    async fetchData() {
      await productline
        .getHourProduct(this.workshop_value)
        .then((response) => {
          console.log(response);
          // 产率
          this.statusData = response.data.data;
          this.dataStatusBackup = response.data.data;
          let task_list = Array.from(
            new Set(this.statusData.map((item) => item.task_id))
          ).sort();
          this.production_line_options = task_list.map((item) => {
            return {
              value: item,
              label: `Task-${item}`,
            };
          });
          this.production_line_options.unshift({
            value: -1,
            label: `全部`,
          });
          if (this.tempValue != -1) {
            this.statusData = this.dataStatusBackup.filter(
              (item) => item.task_id == this.tempValue
            );
          }
          this.machineProductivity = this.statusData;
          
          // 计算hour_products的总和
          this.totalProductivity = this.statusData.reduce((sum, item) => {
            return sum + (item.hour_products || 0);
          }, 0);
          this.totalError = this.statusData.reduce((sum, item) => {
            return sum + (item.alm_count || 0);
          }, 0);
          this.workingMachineNumber = this.statusData.filter(
            (item) => item.machine_status == 1
          ).length;
          //总机器数
          this.machineNumber = this.statusData.length;
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
  },
});
</script>

<style scoped lang="scss">
@import "../../assets/scss/productline.scss";
</style>
